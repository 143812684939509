@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-toastify/dist/ReactToastify.min.css";
@import "~react-accessible-accordion/dist/fancy-example.css";

body,
table,
tr,
td {
  @apply text-gray-600;
}

table {
  @apply w-full;
  td {
    @apply px-4 py-3 last:text-right;
  }
  thead {
    @apply border-b border-gray-300;
    td,
    th {
      @apply text-slate-600 text-xs uppercase font-normal;
    }
  }
  tbody {
    tr {
      @apply border-b border-gray-300 last:border-0 hover:bg-gray-100;
      td {
        @apply last:text-right;
      }
    }
  }
}

.accordion {
  // @apply border-gray-200 rounded shadow-xl;
  @apply border-gray-200 rounded;
  .accordion__item,
  .accordion__heading,
  .accordion__button,
  .accordion__panel {
    background-color: white;
  }

  .accordion__item:first-child {
    @apply rounded-t overflow-hidden;
    .accordion__button,
    .accordion__heading {
      @apply rounded-t overflow-hidden;
    }
  }

  .accordion__item:last-child {
    @apply rounded-b overflow-hidden;
    .accordion__button,
    .accordion__heading {
      @apply rounded-b overflow-hidden;
    }
  }

  .accordion__button:hover {
    @apply bg-slate-50 transition-all;
  }

  .accordion__panel {
    @apply border-t border-gray-200 px-4 py-4;
  }
}
